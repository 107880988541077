<template>
    <b-overlay :show="overlay_show">
        <b-container>
            <b-alert v-model="alert_show" class="position-fixed rounded-1 alert-class" dismissible variant="info"
                style="width: 450px" @dismissed="search_text = ''">
                Пошук товара по: <span class="font-italic">{{ search_text }}</span>
            </b-alert>
            <b-tabs class="mt-3" fill>
                <b-tab title="Доступні товари">
                    <b-row class="mt-2">
                        <b-col cols="4">
                            <b-form-input v-model="filter_text" debounce="500" placeholder="Фільтр" size="sm"
                                class="my-1" @keyup.stop></b-form-input>
                        </b-col>
                        <b-col cols="3"></b-col>
                        <b-col cols="5"> <b-pagination :total-rows="total_row" v-model="current_page" align="fill"
                                :per-page="per_page" size="sm" class="my-1"></b-pagination>
                        </b-col>
                    </b-row>
                    <b-row class="table_place">
                        <b-table small striped hover :items="price_items" :fields="price_fields" id="price_table"
                            :per-page="per_page" :current-page="current_page" bordered head-variant="dark" selectable
                            select-mode="single" ref="priceTable" thead-class="head_class">
                            <template #cell(CNT)="data">
                                <template v-if="data.rowSelected">
                                    <b-input size="sm" style="width: 40px;"
                                        @keyup.stop="changeCnt(data.item.ID, $event)" id="goods_cnt"></b-input>
                                </template>
                                <template v-else>
                                    <b> {{ data.value }}</b></template>
                            </template>
                            <template #cell(NAME)="data">
                                <span>{{ data.value }} &nbsp;</span>
                                <template v-if="data.item.PHOTO">
                                    <b-icon icon="info-circle" variant="primary" :id="`info-${data.item.ID}`"></b-icon>
                                    <b-popover :target="`info-${data.item.ID}`" triggers="hover" placement="topright">
                                        <img :src="`/images/${data.item.PHOTO}`" width="500px">
                                    </b-popover>
                                </template>
                            </template>
                        </b-table>
                    </b-row>
                </b-tab>
                <b-tab :title="'Відібрані товари (' + positions + ')'">
                    <b-row>
                        <b-table small striped hover :items="selected_items" :fields="price_fields" bordered
                            id="selected_table" head-variant="dark" thead-class="head_class" class="mt-3">
                        </b-table>
                    </b-row>
                    <b-row v-if="positions > 0">
                        <b-col cols="8">
                            <b-form-group label="Адреса доставки:" label-for="deliver-select" label-cols="3">
                                <b-form-select id="delivery-select" v-model="deliveryId"
                                    :options="deliveryItems"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col class="text-right" cols="4">
                            <b-btn variant="primary" @click="make_order" :disabled="spinned">
                                Оформити замовлення
                                <b-icon v-if="spinned" icon="arrow-clockwise" animation="spin"></b-icon>
                            </b-btn>
                        </b-col>
                    </b-row>
                </b-tab>
            </b-tabs>
        </b-container>
    </b-overlay>
</template>
<script>
import fetched from '@/helper/fetched'

export default {
    data() {
        return {
            overlay_show: false,
            alert_show: 0,
            filter_text: '',
            search_text: '',
            count_down: 5,
            current_page: 1,
            per_page: 50,
            price_items: [],
            full_items: [],
            selected_items: [],
            price_fields: [
                { key: "NAME", label: "Назва", sortable: false, class: "text-nowrap" },
                { key: "PRODUCER", label: "Виробник", sortable: false, class: "text-nowrap" },
                { key: "GOODS_ID", label: "Код", sortable: false, tdClass: "text-right" },
                { key: "MORION_ID", label: "Код Моріон", sortable: false, tdClass: "text-right" },
                { key: "PRICE_CUSTOM", label: "Ціна митна", sortable: false, tdClass: "text-right" },
                { key: "PRICE", label: "Ціна", sortable: false, tdClass: "text-right" },
                { key: "EXP_DATE", label: "Термін придатності", sortable: false, tdClass: "text-center" },
                { key: "PACKING", label: "Пакування", sortable: false, tdClass: "text-right" },
                { key: "CNT", label: "К-сть", sortable: false, tdClass: "text-right" }
            ],
            search_timeout_hdl: null,
            scroll_timeout_hdl: null,
            positions: 0,
            selected_title: "Відібрані товари",
            spinned: false,
            deliveryId: 0,
            deliveryItems: []
        }
    },
    methods: {
        async getPrice() {
            const data = await fetched.get(this.$apiUrl + 'cl/getPrice')
            this.full_items = data.rows
            this.price_items = this.full_items
            this.overlay_show = false
            // console.log('get data')
        },
        async getDeliveryAddressess() {
            const data = await fetched.get(this.$apiUrl + 'cl/getDeliveryAddresses')
            if (data.status) {
                for (let row of data.rows) {
                    this.deliveryItems.push({ value: row.ID, text: row.ADDRESS })
                }
                if (data.rows.length > 0) {
                    this.deliveryId = data.rows[0].ID
                }
            } else {
                this.deliveryItems = []
            }
        },
        async make_order() {
            this.spinned = true
            const data = await fetched.post(this.$apiUrl + 'cl/order', {
                data: this.selected_items,
                deliveryAddress: this.deliveryId
            })
            if (data.status) {
                this.$store.dispatch('showToastFull', {
                    type: 'success',
                    title: 'Ok',
                    text: "Замовлення прийнято в обробку"
                })
                this.getPrice()
                this.selected_items = []
                this.positions = 0
            } else {
                this.$store.dispatch('showToast', 'Помилка сервера')
                console.log(data)
            }
            this.spinned = false

        },
        searchKeyup(e) {
            let isChanged = false
            if (e.keyCode == 8) {
                this.search_text = this.search_text.slice(0, -1)
                this.alert_show = 5
                isChanged = true
            }
            if (e.key == "+") {
                document.querySelector('#goods_cnt').focus()
            }
            else {
                if (e.key.length == 1) {
                    this.search_text += e.key
                    this.alert_show = 5
                    isChanged = true
                }
            }
            if (isChanged) {
                // const i = this.price_items.findIndex(item => new RegExp(this.search_text, 'i').test(item.NAME))
                const i = this.price_items.findIndex(item => item.NAME.toLowerCase().indexOf(this.search_text) == 0)
                if (i > 0) {
                    // console.log('Current page: ', Math.floor(i / this.per_page) + 1)
                    // console.log('Selected row: ', i % this.per_page)
                    this.current_page = Math.floor(i / this.per_page) + 1
                    clearTimeout(this.search_timeout_hdl)
                    clearTimeout(this.scroll_timeout_hdl)
                    //Таймаут щоб прогрузилась сторінка таблиці
                    this.search_timeout_hdl = setTimeout(() => {
                        this.$refs.priceTable.selectRow(i % this.per_page)
                    }, 200)
                    // Таймаут, щоб з'явилось поле для вводу кількості
                    this.scroll_timeout_hdl = setTimeout(() => {
                        const el = document.querySelector("#goods_cnt")
                        if (el) { el.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" }); }
                    }, 400)

                }
            }
        },
        changeCnt(id, e) { // e - keyboard event
            if (e.key === "Escape" || e.key === "Enter") {
                const selected = this.$refs.priceTable.selectedRows.length - 1
                this.$refs.priceTable.unselectRow(selected)
            } else {
                let value = document.querySelector('#goods_cnt').value
                if (value == '') { value = 0 }
                const idx = this.full_items.findIndex(item => item.ID === id)
                if (idx >= 0) {
                    // console.log(idx)
                    this.full_items[idx].CNT = value
                    if (value > 0) {
                        this.full_items[idx]._rowVariant = 'success'
                    } else {
                        delete this.full_items[idx]._rowVariant
                    }
                    // Рахуємо кількість обраних позицій та суму замовлення
                    this.selected_items = this.full_items.filter((item) => item.CNT > 0)
                    this.positions = this.selected_items.length
                    // this.positions = this.full_items.reduce((a, item) => a + Number(item.CNT), 0)
                    // this.cost = this.full_items.reduce((a, item) => a + Number(item.CNT) * Number(item.PRICE), 0).toFixed(2)
                }
                const idx1 = this.price_items.findIndex(item => item.ID === id)
                if (idx1) {
                    this.price_items[idx1].CNT = value
                    if (value > 0) {
                        this.price_items[idx1]._rowVariant = 'success'
                    } else {
                        delete this.price_items[idx1]._rowVariant
                    }
                }
            }
        }

    },
    mounted() {
        this.overlay_show = true
        this.getPrice()
        this.getDeliveryAddressess()
        window.addEventListener("keyup", this.searchKeyup)
    },
    computed: {
        total_row() {
            return this.price_items.length
        }
    },
    watch: {
        filter_text() {
            // this.price_items = this.full_items.filter(item => new RegExp(this.filter_text, 'i').test(item.NAME))
            this.price_items = this.full_items.filter(item => item.NAME.toLowerCase().indexOf(this.filter_text) >= 0)
        }
    },
    beforeDestroy() {
        window.removeEventListener("keyup", this.searchKeyup)
    }
}
</script>
<style scoped>
#price_table,
#selected_table {
    font-size: 0.8em;
    /* font-family: Arial, Helvetica, sans-serif; */
}

.alert-class {
    right: 0;
    bottom: 0;
    z-index: 2000;
    margin-right: .25rem !important;
}

.table_place {
    max-height: calc(100vh - 175px);
    overflow: auto;
}
</style>
<style>
.head_class {
    position: sticky !important;
    top: 0 !important;
}
</style>